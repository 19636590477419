import React from 'react'
import {all_articles, baseInfo, education, experience, skills} from "../const";

import IndexScroll from "./IndexScroll"
// import NewsList from './NewsList'
import AboutMe from './AboutMe'
import Skills from "./Skills";
import Experience from "./Experience";
import Education from "./Education";
import Footer from "./Footer";

function ResumePageContent() {
    
    return (
        <React.Fragment>
            {/*Первый экран*/}
            <IndexScroll baseInfo={baseInfo.personal} social={baseInfo.socialBtns} />
            
            {/*Обо мне*/}
            <AboutMe about={baseInfo.description} />
            
            {/*Новости*/}
            {/*<NewsList allArticles={all_articles} />*/}
            
            {/*Навыки*/}
            <Skills skills={skills} />
            
            {/*Работа*/}
            <Experience exp={experience} />
            
            {/*Образование*/}
            <Education education={education} />
            
            {/*Подвал*/}
            <Footer footer={baseInfo} />
        </React.Fragment>
    )
}

export default ResumePageContent