import React from 'react'
import {Header, Section, TwoBlocks} from './Header'

class Footer extends React.Component {
    
    footerTtitle(props) {
        return (
            <React.Fragment>
                <h2 className="o-section__heading">Контакты</h2>
                <div className="o-content__body  o-section__description">Мне можно написать на электронную почту или
                    позвонить
                </div>
            </React.Fragment>
        )
    }
    
    footer(props) {
        
        const items = props.personal.contacts
        let col = 12 / items.length
        
        const footerData = items.map(item => {
            return (
                <div className={`o-grid__col-md-${col} o-grid__col-sm-6`} key={item.id}>
                    <div className="o-content">
                        <div className="o-content__body">
                            <h4>{item.name}</h4>
                            <address>{item.content}</address>
                        </div>
                    </div>
                </div>
            )
        })
        
        return (
            <React.Fragment>
                <div className="o-grid">{footerData}</div>
                
                <hr className="c-footer__contact-divider" />
                
                <div className="o-content">
                    <div className="c-footer__bottom">
                        <div className="c-footer__brand">
                            
                            <div className="c-brand">
                                <div className="o-content__body">
                                    {this.footerMyName(props.personal)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </React.Fragment>
        
        )
    }
    
    footerMyName(props) {
        
        return (
            <h1 className="c-brand__title  t-title">
                <span className="c-brand__sizer  c-brand__sizer--sm">
                    <span
                        className="a-footer  c-brand__first-word  t-title__first-word">
                        {props.name}
                    </span>
                    <span
                        className="a-footer  c-brand__second-word  t-title__second-word">
                        {props.lastName}
                    </span>
                </span>
            </h1>
        )
    }
    
    render() {
        
        const title = this.footerTtitle()
        const data = this.footer(this.props.footer)
        
        return (
            <section className="o-section  t-section  o-section--footer">
                <TwoBlocks />
                <div className="o-container">
                    <div className="o-section__container">
                        <Header header={title} />
                        <Section section={data} cls={'c-footer__contact'} />
                    </div>
                </div>
            </section>
        )
    }
}

export default Footer