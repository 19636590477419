import React from 'react'
import {TwoBlocks, Header, Section} from './Header'

class IndexScroll extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: props.baseInfo.name,
            lastName: props.baseInfo.lastName,
            desc: props.baseInfo.desc,
            img: props.baseInfo.img,
            contacts: props.baseInfo.contacts,
            social: props.social.btns
        }
    }

    getAvatar(props) {

        const profile = <img className="c-avatar__img" src={props.img} alt={props.name} />

        return (
            <div className="c-header__avatar">
                <div className="a-header c-avatar">{profile}</div>
            </div>
        )
    }

    getProfile(props) {

        return (
            <React.Fragment>
                <h1 className="c-brand__title t-title">
                    <span className="c-brand__sizer">
                        <span className="a-header c-brand__first-word t-title__first-word">{props.name}</span>
                        <span className="a-header c-brand__second-word t-title__second-word">{props.lastName}</span>
                    </span>
                </h1>
                <h2 className="a-header c-brand__sub-title t-sub-title">
                    <span className="c-brand__sizer">{props.desc}</span>
                </h2>
            </React.Fragment>
        )
    }

    getSocialButtons(props) {
        
        const items = props.social.map(item =>
            <li className="a-header" key={item.name}>
                <a href={item.link}
                   target="_blank"
                   rel="noopener noreferrer"
                   className="c-social-button t-social-button">
                    <i className={`fab fa-${item.name}`} />
                </a>
            </li>
        )

        return <ul className="c-header__social-buttons c-social-buttons">{items}</ul>
    }

    getContacts(props) {

        const items = props.contacts
        let col = 12 / items.length

        let emailAdressText
        if (items.indexOf('email')) {
            const emailArr = items[1].content.split('@')
            emailAdressText = emailArr[0] + '@' + emailArr[1]
        }

        const data = items.map((item, k) =>
            <div className={`o-grid__col-md-${col} o-grid__col-sm-6`} key={item.name.toString()}>
                <div className="a-header o-content in-view">
                    <div className="o-content__body">
                        <h4>{item.name}</h4>
                        <address><p>{k === 1 ? emailAdressText : item.content}</p></address>
                    </div>
                </div>
            </div>
        )

        return <div className="o-grid">{data}</div>

    }

    AreaContent(props) {

        console.log(props)
        
        return (
            <React.Fragment>
                <div className="c-header__top">
                    <div className="c-header__brand">
                        <div className="c-brand">
                            {this.getProfile(props)}
                        </div>
                    </div>
                    {this.getSocialButtons(props)}
                </div>

                <div className="c-header__contact">
                    <hr className="a-header c-header__contact-divider" />
                    {this.getContacts(props)}
                </div>
            </React.Fragment>
        )
    }

    render() {

        const data = this.state

        const avatar = this.getAvatar(data)
        const profileData = this.AreaContent(data)

        return (
            <section className="o-section o-section--header t-section t-section--header">
                <div className="c-header">

                    <TwoBlocks />

                    <div className="o-container">
                        <div className="o-section__container">

                            <Header
                                header={avatar}
                                cls={[
                                    'c-header__header',
                                    'c-header__inner-header'
                                ]}
                            />
                            <Section
                                section={profileData}
                                cls={[
                                    'c-header__content',
                                    'c-header__inner-content'
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default IndexScroll
