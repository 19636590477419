import React from 'react'
import {TwoBlocks, Header, Section} from './Header'

class Experience extends React.Component {
    
    constructor(props) {
        super(props)
        this.stats = {
            experience: props.exp
        }
    }
    
    experienceTitle(props) {
        return (
            <React.Fragment>
                <h2 className="o-section__heading">Работа</h2>
                <div className="o-content__body  o-section__description">Места работы</div>
            </React.Fragment>
        )
    }
    
    experience(props) {
        const data = props.map(item => {
            return (
                <div className="c-timeline__item" key={item.title}>
                    <div className="c-timeline__point  t-timeline__point  t-primary-bg" />
                    <div className="o-grid">
                        <div className="o-grid__col-md-5">
                            <div className="c-work__timeframe">{item.date}</div>
                            <h3 className="c-work__heading">{item.company}</h3>
                            <h4 className="c-work__title">{item.title}</h4>
                        </div>
                        <div className="o-grid__col-md-7"><p>{item.desc}</p></div>
                    </div>
                </div>
            )
        })
        
        return <React.Fragment>{data}</React.Fragment>
    }
    
    render() {
        
        const title = this.experienceTitle()
        const data = this.experience(this.stats.experience)
        
        return (
            <section className="o-section t-section">
                <TwoBlocks />
                <div className="o-container">
                    <div className="o-section__container">
                        <Header header={title} cls={[
                            'o-content'
                        ]} />
                        <Section section={data} cls={[
                            'u-pb-0',
                            'a-experience-timeline  c-timeline  t-border-color'
                        ]} />
                    </div>
                </div>
            </section>
        )
    }
}

export default Experience