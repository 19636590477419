import React from 'react'
import {Header, Section, TwoBlocks} from './Header'

class Education extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            education: props.education
        }
    }
    
    educationTitle(props) {
        return (
            <React.Fragment>
                <h2 className="o-section__heading">Образование</h2>
                <div className="o-content__body  o-section__description">Всё полученное образование на сегодняшний
                    день
                </div>
            </React.Fragment>
        )
    }
    
    education(props) {
        
        const data = props.map(item => {
            return (
                <div className="c-timeline__item" key={item.title}>
                    <div className="c-timeline__point  t-timeline__point  t-primary-bg" />
                    <div className="o-content">
                        <div className="o-grid">
                            <div className="o-grid__col-md-5">
                                <div className="c-work__timeframe">{item.date}</div>
                                <h3 className="c-work__heading">{item.school}</h3>
                                <h4 className="c-work__title">{item.title}</h4>
                                <div className="c-work__location">{item.locaion}</div>
                            </div>
                            <div className="o-grid__col-md-7">
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    
        return <React.Fragment>{data}</React.Fragment>
    }
    
    render() {
        
        const title = this.educationTitle()
        const data = this.education(this.state.education)
        
        return (
            <section className="o-section t-section">
                <TwoBlocks />
                <div className="o-container">
                    <div className="o-section__container">
                        <Header header={title} cls={[
                            'o-content'
                        ]} />
                        <Section section={data} cls={[
                            'u-pt-0',
                            'a-experience-timeline  c-timeline  t-border-color'
                        ]} />
                    </div>
                </div>
            </section>
        )
    }
}

export default Education