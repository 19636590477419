import React from 'react'
import {Header, Section, TwoBlocks} from './Header'

class Skills extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            skills: props.skills
        }
    }
    
    skillsTitle(props) {
        return (
            <React.Fragment>
                <h2 className="o-section__heading">Навыки</h2>
                <div className="o-content__body  o-section__description">Применяемые технологии и используемые
                    инструменты
                </div>
            </React.Fragment>
        )
    }
    
    
    skills(props) {
        const skills = props.map(item => {
            return (
                <React.Fragment key={item.id}>
                    <div className="o-grid__col-sm-6">
                        <div className="o-media  o-media--block">
                            <div className="o-media__figure">
                                <div className="c-number  t-primary-color">
                                    {item.id}
                                </div>
                            </div>
                            <div className="o-media__body  o-content__body">
                                <h3>{item.name}</h3>
                                <p>{item.content}</p>
                                
                                {this.Percents(item.percent)}
                                
                            </div>
                        </div>
                    </div>
                    
                </React.Fragment>
            )
        })
        
        return <>{skills}</>
    }
    
    Percents(props) {
        return (
            <React.Fragment>
                <div className="o_media__figure">
                    <div className="c-number  t-primary-color">{props}
                        <small>%</small>
                    </div>
                </div>
                <div className="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                    <div className="a-progress-bar  c-progress-bar__filler  t-primary-bg"
                         data-percent={`${props}`}
                         style={{width: props+'%'}}/>
                </div>
            </React.Fragment>
        )
    }
    
    render() {
        
        const title = this.skillsTitle()
        const data = this.skills(this.state.skills)
        
        return (
            <section className="o-section t-section">
                <TwoBlocks />
                <div className="o-container">
                    <div className="o-section__container">
                        <Header header={title} cls={[
                            'o-content',
                            'o-section__heading'
                        ]} />
                        <Section section={data} cls={[
                            '',
                            'o-grid'
                        ]} />
                    </div>
                </div>
            </section>
        )
    }
}

export default Skills