import React from 'react'

export const TwoBlocks = () => {
    return (
        <React.Fragment>
            <div className="o-section__header-bg t-section__header" />
            <div className="o-section__content-bg t-section__content" />
        </React.Fragment>
    )
}

export const Header = (props) => {

    const hcls = props.cls ? props.cls : " "
    return (
        <header className={`o-section__header ${hcls[0]} t-section__header`}>
            <div className={hcls[1]}>{props.header}</div>
        </header>
    )
}

export const Section = (props) => {
    const ccls = props.cls ? props.cls : " "
    return (
        <div className={`o-section__content ${ccls[0]} t-section__content`}>
            <div className={ccls[1]}>{props.section}</div>
        </div>
    )
}
