import React from 'react'
import {TwoBlocks, Header, Section} from './Header'

export default function AboutMe(props) {
    
    const data = props.about
    
    function headers(props) {
        return <h2 className="o-section__heading">{props.name}</h2>
    }
    
    function sections(props) {
        
        let descText = props.info.split('. ')
        const text = descText.map(item => {return <p key={item.toString()}>{item}</p>})
        
        return (
            <div className="c-intro">
                <div className="o-content__body">
                    {text}
                </div>
            </div>
        )
    }
    
    const section = sections(data)
    const header = headers(data)
    
    return (
        <section className="o-section t-section">
            <TwoBlocks />
            <div className="o-container">
                <div className="o-section__container">
                    <Header header={header} cls={[  
                        'o-content'
                    ]} />
                    <Section section={section} cls={[
                        'c-intro',
                        'o-content'
                    ]} />
                </div>
            </div>
        </section>
    )
}