export const baseInfo = {
    personal: {
        id: 1,
        name: 'Александр',
        lastName: 'Тарабанов',
        desc: 'web-программист',
        contacts: [
            {id: '1', name: 'телефон', content: '+7 (981) 454 05 17'},
            {id: '2', name: 'email', content: 'alexandr.tarabanov@gmail.com'},
            {id: '3', name: 'Адрес', content: 'Россия · Калининград'}
        ],
        img: '/img/profile.jpg'
    },
    description: {
        id: 2,
        name: 'Обо мне',
        info: 'Программирование - это является основным и любимым занятием. Люблю и очень интересно работать на MODX. Всегда ищу что-то новое и совершенствую те знания, которые уже есть. Основные рабочие инструменты — PHPStorm, terminal, ssh, git, gulp. Умею читать программный код, править вёрстку (HTML, CSS, SCSS, JS, jQuery), имею критический взгляд на вещи, который сформировался давно. Могу написать SQL-запросы к базе данных, извлечь, проанализировать и применить полученную информацию для улучшения ключевых показателей проекта. Работаю на качество. Могу самостоятельно поставить себе задачи, исходя из нужд и целей проекта. Владею грамотным русским языком, как устно, так и письменно. Постоянно совершенствую свои навыки и знания. Обучаюсь в центре "Специалист" при МГТУ им.Н.Э.Баумана - повышаю свою квалификацию по направлениям JavaScript, NODE.js, Ajax, React и некоторым другим. С большим интересом рассматриваю задачи, в которых есть место для неожиданных и интересных решений. А еще мне очень нравится изготавливать домашний хлеб.'
    },
    socialBtns: {
        id: 3,
        btns: [
            {name: 'facebook-f', link: 'https://www.facebook.com/profile.php?id=100014785179182'},
            {name: 'vk', link: 'https://vk.com/as_tarabanov'}
        ]
    }
}

export const experience = [
    {
        id: 1,
        title: 'Web-программист',
        company: 'ООО "Прибой"',
        desc: 'Работа в ООО "Прибой" привлекает, в первую очередь, возможностью полностью раскрывать свой потенциал. Нравится, что есть возможность работать именно с теми технологиями и инструментами, что интересны именно мне в настоящее время. Есть возможность искать, экспериментировать, исследовать и нарабатывать опыт. ',
        date: '2017 - Настоящее время'
    }, {
        id: 2,
        title: 'Web-программист',
        company: 'ГК "RightStarter"',
        desc: 'В период работы в этой компании я получил возможность заняться тем, что мне всегда хотелось и всегда привлекало - программированием. Я получил возможность полностью самостоятельно написать сайт, изучить систему работы и взаимодействия компонентов сайта с сетевыми протоколами, базами данных и прочее. Была возможность поработать с большими объемами данных - вносить их и получать из БД, оперировать ими... Это был действительно хороший и серьезный опыт работы',
        date: '2013 - 2017'
    }
]

export const education = [
    {
        id: 1,
        title: 'Эксперт по программированию на JavaScript (со знанием jQuery, AJAX и Node.js)',
        school: 'Московский государственный технический университет им. Н.Э. Баумана',
        location: 'Москва',
        date: '2018',
        desc: ''
    }, {
        id: 2,
        title: 'Специалист JavaScript',
        school: 'Московский государственный технический университет им. Н.Э. Баумана',
        locaion: 'Москва',
        date: '2018',
        desc: ''
    }, {
        id: 3,
        title: 'JavaScript. Интерактивные веб-приложения',
        school: 'GeekBrains',
        location: '',
        date: '2018',
        desc: ''
    }, {
        id: 4,
        title: 'PHP. Основы веб-разработки',
        school: 'GeekBrains',
        location: '',
        date: '',
        desc: ''
    }, {
        id: 5,
        title: 'Менеджмент организации',
        school: 'Калининградский институт управления',
        location: 'Калининград',
        date: '2008',
        desc: ''
    }
]

export const socialBtns = [
    {
        id: 1,
        link: `https://vk.com/as_tarabanov`,
        title: 'vk'
    },
    {
        id: 2,
        link: 'https://github.com/knowall',
        title: 'github'
    },
    {
        id: 3,
        link: 'https://gitlab.com/alexandr.tarabanov',
        title: 'gitlab'
    },
    {
        id: 4,
        link: 'https://www.facebook.com/profile.php?id=100014785179182',
        title: 'facebook'
    }
]

export const skills = [
    {
        id: 1,
        name: 'html5',
        percent: '80',
        content: 'Использую в работе преимущества, которые дает HTML5'
    },
    {
        id: 2,
        name: 'css3',
        percent: '70',
        content: 'CSS3 сейчас довольно много может. Если есть возможность сделать что-то без применения JS - делаю это'
    },
    {
        id: 3,
        name: 'js',
        percent: '40',
        content: 'JS - это действительно самый активно используемый язык. Быстрый, умеющий очень многое и возможности его внедрения в различные области поистине безграничны'
    },
    {
        id: 4,
        name: 'react',
        percent: '30',
        content: 'Это быстро и современно. Учусь и использую в работе'
    },
    {
        id: 5,
        name: 'node-js',
        percent: '20',
        content: 'Учусь'
    },
    {
        id: 6,
        name: 'sass',
        percent: '50',
        content: 'Не Sass, а SCSS является моим выбором в веб-разработке'
    },
    {
        id: 7,
        name: 'modx',
        percent: '75',
        content: 'Это для души'
    },
    {
        id: 8,
        name: 'gulp',
        percent: '40',
        content: 'Выполняем рутинные действия автоматически'
    },
    {
        id: 9,
        name: 'npm',
        percent: '50',
        content: 'Менеджер пакетов'
    },
    {
        id: 10,
        name: 'js-fiddle',
        percent: '80',
        content: 'Площадка для тестирования'
    },
    {
        id: 11,
        name: 'bitbucket',
        percent: '80',
        content: 'Git-репозиторий. Раньше использовал больше. Сейчас постепенно ухожу с него'
    },
    {
        id: 12,
        name: 'gitlab',
        percent: '85',
        content: 'Мой выбор для хранения своих проектов'
    },
    {
        id: 13,
        name: 'git',
        percent: '75',
        content: 'Это правильно! Любая работа начинается с инициализации Git-репозитория'
    }
]

export const all_articles = [
    {
        id: 1,
        title: 'first article',
        intro: 'intro text',
        desc: 'desc text',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        img: ''
    },
    {
        id: 2,
        title: 'second article',
        intro: 'intro text',
        desc: 'desc text',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        img: ''
    },
    {
        id: 3,
        title: 'third article',
        intro: 'intro text',
        desc: 'desc text',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        img: ''
    },
    {
        id: 4,
        title: 'fourth article',
        intro: 'intro text',
        desc: 'desc text',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        img: ''
    },
    {
        id: 5,
        title: 'fourth article',
        intro: 'intro text',
        desc: 'desc text',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        img: ''
    }
]
