import React, {Component} from 'react'
import {BrowserRouter as Router, Link, Route} from 'react-router-dom'
import {AnimatedSwitch} from 'react-router-transition'

import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'

import './styles/style.scss'
import './styles/_themes.main.scss'

import ResumePageContent from './components/Resume/ResumePageContent'
// import ArticlesPageContent from "./components/Articles/ArticlesPageContent"
// import Login from "./components/Login/Login";
// import Register from "./components/Login/Register";

function NavLink(props) {
    return <li><Link {...props} /></li>
}

class App extends Component {

    render() {
        return (
            <React.Fragment>
                <Router>
                    <React.Fragment>

                        {/*<section className="o-section o-nav__container">*/}
                            {/*<div className="o-nav__top-nav">*/}
                                {/*<div className="o-container">*/}
                                    {/*<ul>*/}
                                        {/*<NavLink to="/">Резюме</NavLink>*/}
                                        {/*<NavLink to="/articles/all">Статьи</NavLink>*/}
                                        {/*<NavLink to="/login/register">Регистрация</NavLink>*/}
                                        {/*<NavLink to="/login">Вход</NavLink>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</section>*/}

                        <AnimatedSwitch
                            atEnter={{opacity: 0}}
                            atLeave={{opacity: 0}}
                            atActive={{opacity: 1}}
                            className="switch-wrapper"
                        >

                            <Route exact path="/" component={ResumePageContent} />
                            {/*<Route path="/articles/all" component={ArticlesPageContent} />*/}

                            {/*<Route exact path="/login" component={Login} />*/}
                            {/*<Route path="/login/:id" component={this.LoginPages} />*/}
                            {/*<Route path="/login/register" component={Register} />*/}

                        </AnimatedSwitch>
                    </React.Fragment>
                </Router>
            </React.Fragment>
        )
    }

    LoginPages({match}) {
        const word = match.params.id
        const compo = word[0].toUpperCase() + word.substring(1)

        return <>{compo}</>
    }

}

export default App
